import React from 'react';
import { Link } from 'gatsby';

const Pagination = ({ currentPage, numPages, baseUrl }) => {
  const isFirst = currentPage === 1;
  const isLast = currentPage === numPages;
  const prevPage = currentPage - 1 === 1 ? '/' : (currentPage - 1).toString();
  const nextPage = (currentPage + 1).toString();

  if (!isFirst || !isLast) {
    return (
      <nav className="border-t mt-10 py-10 px-4 flex items-center justify-between sm:px-0">
        <div className="w-0 flex-1 flex">
          {!isFirst && (
            <Link to={`/${baseUrl}${prevPage}`} rel="prev" className="btn">
              <svg
                className="mr-3 h-5 w-5"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path
                  fillRule="evenodd"
                  d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
                  clipRule="evenodd"
                />
              </svg>
              Previous
            </Link>
          )}
        </div>

        {/* Showing page numbers... not ready yet
         <div className="hidden md:flex space-x-4">
          {Array.from({ length: numPages }, (_, i) => (
            <Link
              key={`pagination-number${i + 1}`}
              to={`/${i === 0 ? '' : i + 1}`}
              className="color-mode-color text-xl font-semibold no-underline"
            >
              {i + 1}
            </Link>
          ))}
        </div> */}

        <div className="w-0 flex-1 flex justify-end">
          {!isLast && (
            <Link to={`/${baseUrl}${nextPage}`} rel="next" className="btn">
              Next
              <svg
                className="ml-3 h-5 w-5"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path
                  fillRule="evenodd"
                  d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </Link>
          )}
        </div>
      </nav>
    );
  }
  return null;
};

export default Pagination;
