import React from 'react';
import { graphql } from 'gatsby';

import BlockContent from '@components/BlockContent';
import ContentNarrow from '@components/ContentNarrow';
import Layout from '@components/Layout';

import { moduleSettings } from '@utils';
import { Container } from '@elements';
import Pagination from '../components/Pagination';
import Post from '../components/Post';

const blogSettings = moduleSettings('blog');

const Posts = ({ data, pageContext }) => {
  const posts = data.posts.edges;

  // Pagination
  const { currentPage, numPages } = pageContext;

  return (
    <Layout>
      <div className="color-mode-background-200">
        <div className="relative max-w-7xl mx-auto">
          <ContentNarrow>
            <div className="text-center">
              <h1>
                {data?.category?.pageInfo?.name && (
                  <>
                    Category:{' '}
                    <span className="text-primary">
                      {data.category.pageInfo.name}
                    </span>
                  </>
                )}
              </h1>
              <BlockContent blocks={data.category._rawDescription || []} />
            </div>
          </ContentNarrow>
          <Container className="justify-center grid">
            <div
              className={`grid gap-8 lg:grid-cols-${
                data.settings && data.settings.columns
                  ? data.settings.columns
                  : '3'
              }`}
            >
              {posts.map((edge) => {
                const post = edge.node;
                return (
                  <Post className="max-w-3xl" key={post._id} post={post} />
                );
              })}
            </div>
          </Container>
          <Pagination
            currentPage={currentPage}
            numPages={numPages}
            baseUrl={`${blogSettings.baseName}/`}
          />
        </div>
      </div>
    </Layout>
  );
};

export default Posts;

export const query = graphql`
  query($skip: Int!, $limit: Int!, $category: String!) {
    posts: allSanityPost(
      sort: { fields: [publishedAt], order: DESC }
      limit: $limit
      skip: $skip
      filter: {
        categories: {
          elemMatch: { pageInfo: { slug: { current: { eq: $category } } } }
        }
      }
    ) {
      edges {
        node {
          _id
          _rawExcerpt(resolveReferences: { maxDepth: 10 })
          _rawBody(resolveReferences: { maxDepth: 10 })
          author {
            _id
            pageInfo {
              name
              slug {
                current
              }
            }
            image {
              asset {
                _id
                fluid(maxWidth: 80) {
                  ...GatsbySanityImageFluid
                }
              }
              alt
              caption
            }
          }
          _rawAuthor
          categories {
            _id
            pageInfo {
              slug {
                current
              }
              name
            }
          }
          excerpt
          image {
            asset {
              _id
              fluid(maxWidth: 1600) {
                ...GatsbySanityImageFluid
              }
            }
            alt
            caption
          }
          pageInfo {
            slug {
              current
            }
            name
          }
          publishedAt(formatString: "MMMM DD, YYYY")
          dateReadable: publishedAt(formatString: "MMMM DD, YYYY")
          date: publishedAt(formatString: "YYYY-MM-DD")
          seo {
            ...SeoFragment
          }
          subTitle
          readTime
          tags
        }
      }
    }
    category: sanityCategory(
      pageInfo: { slug: { current: { eq: $category } } }
    ) {
      pageInfo {
        name
      }
      _rawDescription(resolveReferences: { maxDepth: 10 })
    }
    settings: sanityBlogSettings {
      _rawContent(resolveReferences: { maxDepth: 10 })
      columns
    }
  }
`;
